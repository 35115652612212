var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"collect-com full-height"},[_c('vue-scroll',{staticStyle:{"height":"calc(100% - 1rem)"},attrs:{"ops":_vm.$root.scrollOpsY}},[_c('div',{staticClass:"p-sm"},_vm._l((_vm.dataList),function(folder){return _c('div',{key:folder.groupId,staticClass:"folder m-b-sm pointer"},[_c('div',{staticClass:"folder-hd flex justify-between items-center text-lightblue f15 m-b-sm",on:{"click":function($event){return _vm.expandFolder(folder)}}},[_c('div',{staticClass:"text-white flex"},[_c('span',{staticClass:"weight flex items-center",attrs:{"title":folder.groupName,"draggable":"true"},on:{"dragstart":function($event){return _vm.dragstartFolder($event, folder)},"dragend":_vm.dragendFolder}},[_c('img',{staticClass:"m-r-xs",attrs:{"src":require("../assets/resource/collect-folder.png"),"width":"20"}}),_vm._v(_vm._s(_vm.subName(folder.groupName)))]),_vm._v("("+_vm._s(folder.deviceCount)+") ")]),(_vm.ope)?_c('i',{staticClass:"icon-project",attrs:{"title":"摄像机组投屏"},on:{"click":function($event){return _vm.projectHandle(folder,$event)}}}):_vm._e()]),(
            folder.show &&
              folder.dataCameraList &&
              folder.dataCameraList.length
          )?_vm._l((folder.dataCameraList),function(item){return _c('div',{key:item.cameraId,staticClass:"flex items-center m-b-sm p-w-sm",attrs:{"title":item.cameraName}},[_c('span',{class:[
                'icon-style  m-r-xs',
                {
                  online: item.cameraStatus === '1',
                  offline: item.cameraStatus === '0',
                  error: item.cameraStatus === '2',
                  yunOffline: item.cameraStatus === '0' && _vm.$g.treeDefinate,
                } ]}),_c('span',{staticClass:"flex-one ellipsis pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectShot(item)}}},[_vm._v(_vm._s(item.cameraName))])])}):_vm._e()],2)}),0)]),_c('camera-group-setting',{ref:"cameraGroupSetting",attrs:{"visible":_vm.visibleGroupSetting},on:{"close":_vm.closeGroupSettingHandle,"currentScreen":_vm.currentScreenHandle}}),(_vm.ope)?_c('turn-dialog',{attrs:{"selectAllMode":"group","visible":_vm.turnVisible},on:{"closeDialog":function($event){_vm.turnVisible = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }