<template>
  <!--  <div :class="{ scroll: $g.treeDefinate }">  :style="getStyle" -->
  <div class="full-width">
    <ul class="flex flex-end menu-list flex-wrap full-width">
      <li
        v-for="(item, index) in list"
        :class="[
          `${list.length >= 5 ? 'flex-w' : 'flex-one'}`,
          {
            'text-center pointer text-lightblue': true,
            current: index === curIndex,
            'not-cursor': cameraLoading,
          },
        ]"
        :key="item.id"
        @click="selectHandle(item, index)"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'LeftMenu',
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    cameraRouteFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      curIndex: 0,
    };
  },
  computed: {
    ...mapState({
      cameraLoading: (state) => state.collect.cameraLoading,
    }),
    getStyle() {
      return this.$g.treeDefinate && this.cameraRouteFlag
        ? { width: '140%' }
        : {};
    },
  },
  watch: {},
  methods: {
    selectHandle(item, index) {
      if (this.cameraLoading) {
        return;
      }
      this.curIndex = index;
      this.$emit('showCom', item);
    },
    resetCurrentIndex() {
      this.curIndex = 0;
    },
    clearCurrentIndex() {
      this.curIndex = '';
    },
  },
};
</script>

<style scoped>
.menu-list {
  padding-left: 1px;
}
.menu-list li {
  background: rgba(150, 185, 228, 0.1);
  line-height: 30px;
  margin-right: 1px;

  /* line-height: 3rem;
  margin-right: 0.1rem; */
  border: 1px solid rgba(150, 185, 228, 0);
}
/* .menu-list li:last-child {
  margin-right: 0;
} */
.menu-list li:hover,
.menu-list li.current {
  font-weight: 400;
  color: #ffffff;
  background: linear-gradient(0deg, #1c5bb2, #0f283d);

  border-image: linear-gradient(0deg, #4679bf, #143354) 1 1;
}
.scroll {
  overflow: auto;
}
.flex-w {
  width: calc(20% - 1px);
}
</style>
