<template>
  <div class="wrapperList m-t-sm m-b-sm" ref="viewport">
    <div class="list-menu" ref="listView" id="listView">
      <span
        v-for="item in list"
        :class="['pointer', { current: curId === item.id }]"
        :key="item.id"
        @click="selectItem(item)"
        >{{ item.name }}</span
      >
    </div>
  </div>
</template>

<script>
import BScroll from '@better-scroll/core';
export default {
  name: 'ListMenu',
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    list() {
      this.$nextTick(() => {
        this.scroll();
      });
    },
  },
  data() {
    return {
      curId: 0,
      wd: 0,
      scroller: null,
      current: 4,
    };
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {
      this.scroll();
    });
  },
  methods: {
    //重置标签属性
    resetCurrentIndex(curId) {
      this.curId = curId;
    },
    selectItem(item) {
      this.curId = item.id;
      this._adjust(item.id);
      this.$emit('showCom', item);
    },
    //设置宽度
    setWd() {
      let oListView = document.getElementById('listView');
      let list = oListView.children;
      for (let i = 0; i < list.length; i++) {
        this.wd += list[i].offsetWidth + 10;
      }
    },
    scroll() {
      let oListView = document.getElementById('listView');
      let list = oListView.children;
      let wd = 0;
      for (let i = 0; i < list.length; i++) {
        wd += list[i].offsetWidth + 10;
      }
      this.$refs.listView.style.width = wd + 'px';
      if (!this.scroller) {
        this.scroller = new BScroll('.wrapperList', {
          scrollX: true,
        });
      } else {
        this.scroller.refresh();
      }
    },
    _adjust(tabId) {
      const viewportWidth = this.$refs.viewport.clientWidth;
      const tabListWidth = this.$refs.listView.clientWidth;
      const minTranslate = Math.min(0, viewportWidth - tabListWidth);
      const middleTranslate = viewportWidth / 2;
      const items = this.$refs.listView.children;
      let width = 0;
      this.list.every((item, index) => {
        if (item.id === tabId) {
          return false;
        }
        width += items[index].clientWidth;
        return true;
      });
      let translate = middleTranslate - width;
      translate = Math.max(minTranslate, Math.min(0, translate));
      this.scroller.scrollTo(translate, 0, 300);
    },
  },
};
</script>

<style lang="less" scoped>
.wrapperList {
  /*   position: relative;
  left: 0;
  top: 0; */
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  z-index: 999;
  color: #fff;
  .list-menu {
    span {
      line-height: 24px;
      padding: 0 10px;
      border-radius: 16px;
      margin: 0 5px;
      display: inline-block;
      &.current {
        background: #1cbee1;
      }
    }
  }
}
</style>
