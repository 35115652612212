<template>
  <div class="collect-com full-height">
    <!-- <div class="text-right text-lightblue p-h-xs" v-if="ope">
      <span class="m-r-sm pointer" @click="newTurnHandle()"
        ><i class="icon-s icon-turn ve-m m-r-xs"></i>新建轮巡</span
      >
    </div> -->
    <vue-scroll :ops="$root.scrollOpsY" style="height: calc(100% - 1rem);">
      <div class="p-sm">
        <div
          class="folder m-b-sm pointer"
          v-for="folder in dataList"
          :key="folder.groupId"
        >
          <div
            class="folder-hd flex justify-between items-center text-lightblue f15 m-b-sm"
            @click="expandFolder(folder)"
          >
            <div class="text-white flex">
              <span class="weight flex items-center" :title="folder.groupName"  draggable="true" @dragstart="dragstartFolder($event, folder)" @dragend="dragendFolder"
                ><!-- <a-icon type="folder" class="m-r-xs" /> --><img
                  src="../assets/resource/collect-folder.png"
                  width="20"
                  class="m-r-xs"
                />{{ subName(folder.groupName) }}</span
              >({{ folder.deviceCount }})
            </div>
            <i
              title="摄像机组投屏"
              class="icon-project"
              @click="projectHandle(folder,$event)"
              v-if="ope"
            ></i>
          </div>
          <template
            v-if="
              folder.show &&
                folder.dataCameraList &&
                folder.dataCameraList.length
            "
          >
            <div
              class="flex items-center m-b-sm p-w-sm"
              v-for="item in folder.dataCameraList"
              :key="item.cameraId"
              :title="item.cameraName"
            >
              <span
                :class="[
                  'icon-style  m-r-xs',
                  {
                    online: item.cameraStatus === '1',
                    offline: item.cameraStatus === '0',
                    error: item.cameraStatus === '2',
                    yunOffline: item.cameraStatus === '0' && $g.treeDefinate,
                  },
                ]"
              ></span>
              <span
                class="flex-one ellipsis pointer"
                @click.stop.prevent="selectShot(item)"
                ><!-- {{ item.cameraName }}
                {{ item.derection | getDirectionArrow }} -->{{
                  item.cameraName
                }}</span
              >
            </div>
          </template>
        </div>
      </div>
    </vue-scroll>
    <camera-group-setting
      ref="cameraGroupSetting"
      :visible="visibleGroupSetting"
      @close="closeGroupSettingHandle"
      @currentScreen="currentScreenHandle"
    />
    <turn-dialog
      @closeDialog="turnVisible = false"
      selectAllMode="group"
      :visible="turnVisible"
      v-if="ope"
    ></turn-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import CameraGroupSetting from './CameraGroupSetting';
import TurnDialog from './TurnDialog';
import { getStorage, setStorage } from '@/tools';
import { DIRECTOINARROW } from '@/tools';
import { CODE_OK } from '@/request/config_code';
export default {
  name: 'CameraGroupNew',
  props: {
    ope: {
      type: Boolean,
      default: true,
    },
    folderType: {
      type: Boolean,
      default: false,
    },
    noPlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visibleGroupSetting: false,
      isEdit: false,
      folderId: '',
      folderName: '',
      paginationOpt: {
        current: 0,
        defaultPageSize: 0,
      },
      dataList: [],
      projectDataList: [],
      turnVisible: false,
    };
  },
  computed: {
    ...mapState({
      amapIns: state => state.home.amapIns,
    }),
  },
  watch: {},
  components: { CameraGroupSetting, TurnDialog },
  mounted() {
    // this.getCollectList();
    this._getCameraGroupLists();
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([]),
    //文件夹拖拽
    async dragstartFolder(event, folder) {
      let paramsObj = {
        groupId: folder.groupId,
        params: {
          currPage: this.paginationOpt.current,
          pageSize: this.paginationOpt.defaultPageSize,
        },
      };
      const {code,data} = await this.$api.cameraGroup.getCemeraListDetail(paramsObj);

      if(code === 200) {
        this.$emit('dragCameraList',data)
      }
 
    },
    //文件夹拖拽结束
    dragendFolder(){
      this.$emit('dragendCameraList');
    },
    subName(name) {
      return name.length > 10 ? name.slice(0, 10) + '...' : name;
    },

    getTitle(item) {
      return `桩号:${item.kmHmPile}.方向:${
        DIRECTOINARROW[item.derection]
      },兴趣点名称:${item.poiName}`;
    },
    _getCameraGroupLists() {
      let obj = {
        currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize,
      };
      this.$api.cameraGroup.getGroupList(obj).then(res => {
        if (res.code === CODE_OK) {
          this.dataList = this.dataList.concat(res.data).map(item => {
            item.show = false;
            return item;
          });
          if (!this.folderType) {
            this.getCameraDetail(this.dataList[0]);
          }
        }
      });
    },
    selectShot(item) {
      if (this.noPlay) {
        /* this.$emit('getCamera', {
          type: 'camera',
          cameraId: item.cameraId,
        }); */
        item.type = 'camera';
        this.$emit('getCamera', item);
        return;
      }

      // console.log(item);
      if (item.otherOrganizationId === 1) {
        this.$message.error('暂无观看权限！');
        return;
      }
      let cameraInfo = item;
      let longitudeArr = cameraInfo.longAndLati.split('/');
      cameraInfo.lonlat = [longitudeArr[0], longitudeArr[1]];
      /*   if (cameraInfo.cameraStatus == 1) { */
      this.amapIns.cameraInfoWindowShow({
        data: {
          position: [longitudeArr[0], longitudeArr[1]],
          onlineStatus: cameraInfo.status,
          cameraName: cameraInfo.cameraName,
          cameraId: cameraInfo.cameraId,
          cameraNum: cameraInfo.cameraNum,
          organizationId: cameraInfo.organizationId,
          regionCode: cameraInfo.regionCode,
          roadId: cameraInfo.roadId,
          kmPile: cameraInfo.kmPile,
          hmPile: cameraInfo.hmPile,
          classify: cameraInfo.classify,
          cameraType: cameraInfo.cameraType,
          zoomlevel: cameraInfo.zoomlevel,
          source: cameraInfo.source,
          khPile: cameraInfo.kmHmPile,
          collectFlag: 1,
          lonlat: [longitudeArr[0], longitudeArr[1]],
          resolutionValue: 0,
        },
      });
      if (this.$g.isDepart) {
        this.amapIns.addActCameraIcon(cameraInfo);
      } else {
        this.amapIns.addVideoMarker(cameraInfo);
      }
      /*   } else {
          this.$message.error('该摄像机不在线，不能拉流！');
        } */
      // return;

      /* let data = {
        ...this.screenShotSearchParams,
        ...{ cameraId: item.cameraId },
      };
      this.setScreenShotSearchParams(data);
      this.getScreenShotInfo(data); */
    },

    expandFolder(folder) {
      if (this.folderType) {
        this.$emit('getCamera', { needType: 'group', groupId: folder.groupId });
        return;
      }
      if (!folder.show) {
        this.getCameraDetail(folder);
        return;
      }
      folder.show = !folder.show;
    },
    getCameraDetail(folder) {
      let paramsObj = {
        groupId: folder.groupId,
        params: {
          currPage: this.paginationOpt.current,
          pageSize: this.paginationOpt.defaultPageSize,
        },
      };
      this.$api.cameraGroup.getCemeraListDetail(paramsObj).then(res => {
        if (res.code === CODE_OK) {
          folder.dataCameraList = res.data;
          // this.paginationOpt.total = res.total;
          folder.show = !folder.show;
        }
      });
    },
    closeGroupSettingHandle() {
      this.visibleGroupSetting = false;
    },
    currentScreenHandle(curScree) {
      let currentScreen = curScree.mode;
      let tit = curScree.litTit;
      let interval = curScree.interval;
      // this.visibleGroupSetting = false;
      // console.log(currentScreen, tit, this.projectDataList);
      let { href } = this.$router.resolve({
        path: `/${currentScreen}-window${tit}`,
      });
      let paramsObj = {
        groupId: this.groupId,
        params: {
          currPage: this.paginationOpt.current,
          pageSize: this.paginationOpt.defaultPageSize,
        },
      };
      this.$refs['cameraGroupSetting'].setLoading(true);
      this.$api.cameraGroup.getCemeraListDetail(paramsObj).then(res => {
        if (res.code === CODE_OK) {
          this.$refs['cameraGroupSetting'].setLoading(false);
          this.projectDataList = res.data;
          this.visibleGroupSetting = false;
          window.open(`${href}?isGroup=1`, '_blank');
          setStorage('groupCamera', {interval,arr:this.projectDataList});
        }
      });
    },
    projectHandle(folder,e) {
      e.stopPropagation();
      if (!this.hasPermission(1092111313)) {
        this.permissionWarning();
        return;
      }

      let cameraInfoStore = getStorage('groupCamera');
      if (cameraInfoStore && JSON.stringify(cameraInfoStore) != '{}') {
        this.$message.error('每组摄像机只能同时投屏一次！');
        return;
      }
      this.visibleGroupSetting = true;
      this.groupId = folder.groupId;
    },
    newTurnHandle() {
      this.turnVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.collect-com {
  padding-top: 0.5rem;
}
</style>
<style scoped>
.title-con {
  border-bottom: 1px dashed rgba(40, 69, 106, 1);
}
.icon-project {
  background: url('../assets/resource/icon-project.png') no-repeat;
  background-size: 100% 100%;
  width: 16px;
  height: 13px;
}
</style>
